body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100vh;
}

.main {
  height: 100%;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
  color:#4639CA !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color:#4639CA !important;
}
span .css-1hv8oq8-MuiStepLabel-label.Mui-active {
  color:#4639CA ;
}
span .css-1hv8oq8-MuiStepLabel-label.Mui-completed {
  color:#4639CA ;
}